// customStyles.js
const styles = {
  container: {
    minHeight: '300px',
    maxWidth: '100%', // Fill parent before overflowing
    maxHeight: '100%', // Fill parent before overflowing
    borderRadius: '5px',
    overflow: 'auto',
    cursor: 'text',
    backgroundColor: '#212121',
    backgroundSize: 'cover'
  },
  content: {
    padding: '16px',
    height: '80vh',
    width: '80vw',
    fontSize: '16px',
    color: '#FFFFFF',
    fontFamily: 'monospace'
  },
  inputArea: {
    display: 'inline-flex',
    width: '100%'
  },
  promptLabel: {
    paddingTop: '3px',
    color: 'pink',
    fontWeight: '600'
  },
  inputText: {
    fontSize: '16px',
    color: 'pink',
    fontFamily: 'monospace'
  },
  input: {
    border: '0',
    padding: '0 0 0 7px',
    margin: '0',
    flexGrow: '100',
    width: '100%',
    height: '22px',
    background: 'transparent',
    outline: 'none' // Fix for outline showing up on some browsers
  },
  directory: {
    color: 'pink',
    fontWeight: 'bold'
  }
};

export default styles;

