import React, { useState, useEffect, useRef } from 'react';
import Terminal from 'react-console-emulator';
import { commands, formatPath, currentPath } from './commands';
import customStyles from './customStyles';
import AutoComplete from './AutoComplete';

const TerminalComponent = () => {
  const [prompt, setPrompt] = useState(`me@bisheshank:${formatPath(currentPath)}$`);
  const [, setInputValue] = useState('');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const terminalRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const updatePrompt = (event) => {
      setPrompt(`me@bisheshank:${event.detail}$`);
    };
    window.addEventListener('path-changed', updatePrompt);
    return () => {
      window.removeEventListener('path-changed', updatePrompt);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('input', handleInputChange);
      inputRef.current.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('input', handleInputChange);
        inputRef.current.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [autocompleteOptions, selectedOptionIndex]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    updateAutocompleteOptions(value);
  };

  const updateAutocompleteOptions = (value) => {
    const commandNames = Object.keys(commands);
    const matchingCommands = commandNames.filter(cmd => cmd.startsWith(value));
    setAutocompleteOptions(matchingCommands);
    setShowAutocomplete(matchingCommands.length > 0);
    setSelectedOptionIndex(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      if (showAutocomplete) {
        const option = autocompleteOptions[selectedOptionIndex];
        handleAutocomplete(option);
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => 
        (prevIndex + 1) % autocompleteOptions.length
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedOptionIndex((prevIndex) => 
        (prevIndex - 1 + autocompleteOptions.length) % autocompleteOptions.length
      );
    }
  };

  const handleAutocomplete = (option) => {
    setInputValue(option);
    setShowAutocomplete(false);
    if (terminalRef.current) {
      terminalRef.current.setInput(option);
    }
  };

  return (
    <div>
      <Terminal
        ref={terminalRef}
        commands={commands}
        welcomeMessage={'Welcome to my website!\n Type "help" to see all available commands. Type "gui" to go to a simple gui website.'}
        promptLabel={<span style={customStyles.promptLabel}>{prompt}</span>}
        autoFocus={true}
        ignoreCommandCase
        errorText={'Command not found! Type "help" to see all available commands.'}
        styleEchoBack='fullInherit'
        noEchoBack={false}
        style={customStyles.container}
        contentStyle={customStyles.content}
        inputAreaStyle={customStyles.inputArea}
        inputStyle={{...customStyles.input, ref: inputRef}}
        inputTextStyle={customStyles.inputText}
        dangerMode
      />
      {showAutocomplete && (
        <div style={{position: 'absolute', bottom: '40px', left: '20px'}}>
          <AutoComplete 
            options={autocompleteOptions} 
            onSelect={handleAutocomplete}
            selectedIndex={selectedOptionIndex}
          />
        </div>
      )}
    </div>
  );
};

export default TerminalComponent;
