// src/App.js

import React from 'react';
import TerminalComponent from './TerminalComponent';

const App = () => (
  <div>
    <TerminalComponent />
  </div>
);

export default App;

