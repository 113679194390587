import React from 'react';
import PropTypes from 'prop-types';

const AutoComplete = ({ options, onSelect, selectedIndex }) => {
  return (
    <div style={{
      backgroundColor: '#333',
      border: '1px solid #555',
      borderRadius: '4px',
      zIndex: 1000
    }}>
      {options.map((option, index) => (
        <div 
          key={index} 
          onClick={() => onSelect(option)}
          style={{
            padding: '5px 10px',
            cursor: 'pointer',
            backgroundColor: index === selectedIndex ? '#444' : 'transparent',
          }}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

AutoComplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default AutoComplete;
