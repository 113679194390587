// src/commands.js

const fileSystem = {
  home: {
    courses: {
      'CS': {
        'CS0190': 'Accelerated Introduction to Computer Science',
        'CS0300': 'Computer Systems Fundamentals',
        'CS1230': 'Computer Graphics',
        'CS1260': 'Compilers + Program Analysis',
        'CS1270': 'Database Management Systems',
        'CS1470': 'Deep Learning',
        'CS1570': 'Design/Analysis of Algorithms',
        'CS1680': 'Computer Networks',
        'CS1710': 'Logic for Systems',
        'CS2470': 'Deep Learning',
        'CS2670': 'Operating Systems'
      },
      'MATH': {
        'MATH0350': 'Multivariable Calculus with Theory',
        'MATH0520': 'Linear Algebra',
        'MATH1230': 'Graph Theory',
        'MATH1530': 'Abstract Algebra'
      },
      'ECON': {
        'ECON0110': 'Principles of Economics',
        'ECON1090': 'Introduction to Game Theory'
      },
      'HIST': {
        'HIST0285A': 'Modern Genocide + Other Crimes'
      },
      'VISA': {
        'VISA0100': 'Studio Foundation'
      },
      'APMA': {
        'APMA1650': 'Statistical Inference I'
      },
      'CLPS': {
        'CLPS0300': 'Introduction to Linguistics',
        'CLPS1342': 'Formal Semantics'
      },
      'PHIL': {
        'PHIL1635': 'Advanced Deductive Logic'
      },
      'ENVS': {
        'ENVS0714': 'Colonial Ecologies'
      },
      'UNIV': {
        'UNIV0123': 'Peer Advising Practical Intro'
      }
    },
    'about.txt': 'Hi! I am Bisheshank. I am a rising junior at Brown University studying Mathematics-Computer Science.',
    'projects.txt': 'List of my projects... (wip)',
  }
};

export let currentDirectory = fileSystem.home;
export let currentPath = '/home';

export const formatPath = (path) => {
  return path.replace(/^\/home/, '~').replace(/\/$/, '');
};

const languages = [
  'C/C++/C#',
  'Python',
  'Forge',
  'R',
  'Rust',
  'Javascript',
  'HTML',
  'SQL',
  'Racket',
  'Scripts',
];

const tools = [
  'Git',
  'Docker',
  'Vim',
  'Linux',
  'Adobe CC Suite',
  'Latex',
  'Unity',
  'Unreal Engine',
  'Tensorflow',
  'Pygame',
  'Z3',
  'Alloy',
  'CVC5',
];

const formatTree = (title, items) => {
  const formatNode = (node, indent = '├──') => {
    if (typeof node === 'string') {
      return `${indent}${node}\n`;
    }
    if (typeof node === 'object') {
      return Object.entries(node)
        .map(([, value]) => {
          return `${formatNode(value, `${indent}  `)}`;
        })
        .join('');
    }
    return '';
  };
  return `${title}\n${formatNode(items)}`;
};

export const commands = {
  echo: {
    description: 'Echo a passed string.',
    usage: 'echo <string>',
    fn: (...args) => args.join(' ')
  },
  about: {
    description: 'About me.',
    usage: 'about',
    fn: () => 'Hi! I am Bisheshank. I am a rising junior at Brown University studying Mathematics-Computer Science. I also draw a little in my free time.'
  },
  resume: {
    description: 'Get a link to my resume.',
    usage: 'resume',
    fn: () => {
      window.open('https://www.bisheshank.com/resume.pdf', '_blank');
      return 'Opening resume...';
    }
  },
  linkedin: {
    description: 'Get a link to my LinkedIn profile.',
    usage: 'linkedin',
    fn: () => {
      window.open('https://www.linkedin.com/in/bisheshank/', '_blank');
      return 'Opening LinkedIn profile...';
    }
  },
  github: {
    description: 'Get a link to my GitHub profile.',
    usage: 'github',
    fn: () => {
      window.open('https://www.github.com/bisheshank/', '_blank');
      return 'Opening GitHub profile...';
    }
  },
  languages: {
    description: 'List the languages and tools I know.',
    usage: 'languages',
    fn: () => {
      return `${formatTree('Languages:', languages)}`;
    }
  },
  tools: {
    description: 'List the tools I know.',
    usage: 'tools',
    fn: () => {
      return `${formatTree('Tools:', tools)}`;
    }
  },
  setup: {
    description: 'My current setup.',
    usage: 'setup',
    fn: () => {
      return `
        Editor: Nvim\n
        OS: MacOS\n
        Terminal: Alacritty\n
        Browser: Qutebrowser\n
        Shell: Zsh\n
        WM: Yabai\n
        `;
    }
  },
  courses: {
    description: 'List all courses in a tree format.',
    usage: 'courses',
    fn: () => {
      return formatTree('Courses:', fileSystem.home.courses);
    }
  },
  cd: {
    description: 'Change directory',
    usage: 'cd <directory>',
    fn: (dir) => {
      if (!dir) {
      return `
Usage: cd <directory>

Options:
    <directory>    Name of the directory to change to
    ..             Move to the parent directory
    ~              Move to the home directory
    home           Move to the home directory

Examples:
    cd documents
    cd ..
    cd ~

Current directory: ${formatPath(currentPath)}

Tip: Use 'ls' to list the contents of the current directory.
`;
    }

      // Remove trailing slash and convert to lowercase for comparison
      dir = dir.replace(/\/$/, '').toLowerCase();
        
      if (dir === '~' || dir === 'home') {
        currentDirectory = fileSystem.home;
        currentPath = '/home';
        const formattedPath = formatPath(currentPath);
        window.dispatchEvent(new CustomEvent('path-changed', { detail: formattedPath }));
        return `Changed to ${formattedPath}`;
      }

      if (dir === '..') {
        if (currentPath === '/home') {
          return 'Already at root directory';
        }
        const pathParts = currentPath.split('/').filter(Boolean);
        pathParts.pop();
        currentPath = '/' + pathParts.join('/');
        currentDirectory = pathParts.reduce((acc, curr) => acc && acc[curr], fileSystem);

        // After updating currentPath, dispatch a custom event
        const formattedPath = formatPath(currentPath);
        window.dispatchEvent(new CustomEvent('path-changed', { detail: formattedPath }));

        return `Changed to ${formattedPath}`;
      }

      // Find the directory case-insensitively
      const matchingDir = Object.keys(currentDirectory).find(
        key => key.toLowerCase() === dir
      );

      if (matchingDir) {
        if (typeof currentDirectory[matchingDir] === 'object') {
          currentDirectory = currentDirectory[matchingDir];
          currentPath += currentPath.endsWith('/') ? matchingDir : `/${matchingDir}`;

          const formattedPath = formatPath(currentPath);
          window.dispatchEvent(new CustomEvent('path-changed', { detail: formattedPath }));
          return `Changed to ${formattedPath}`;
        } else {
          return `${matchingDir} is not a directory`;
        }
      } else {
        return `Directory not found: ${dir}`;
      }
    }
  },

    mkdir: {
    description: 'Create a new directory',
    usage: 'mkdir <directory_name>',
    fn: (dirName) => {
      if (!dirName) {
        return 'Usage: mkdir <directory_name>';
      }
      
      // Remove any leading/trailing whitespace and slashes
      dirName = dirName.trim().replace(/^\/+|\/+$/g, '');
      
      // Split the path into parts
      const parts = dirName.split('/');
      
      let currentDir = currentDirectory;
      
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        
        if (part === '.' || part === '') continue;
        if (part === '..') {
          return 'Cannot create directory with ".." in the path';
        }
        
        if (i === parts.length - 1) {
          // This is the final part, create the directory here
          if (currentDir[part]) {
            return `Cannot create directory '${part}': File or directory already exists`;
          }
          currentDir[part] = {};
          return `Directory '${dirName}' created successfully`;
        } else {
          // This is an intermediate part, navigate to it
          if (!currentDir[part] || typeof currentDir[part] !== 'object') {
            return `Cannot create directory '${dirName}': Path does not exist`;
          }
          currentDir = currentDir[part];
        }
      }
    }
  },

  ls: {
    description: 'List directory contents',
    usage: 'ls',
    fn: () => {
      return Object.keys(currentDirectory).map(key => {
        if (typeof currentDirectory[key] === 'object') {
          return `<span style="color: pink; font-weight: 600;">${key}/</span>`;
        } else {
          return `${key}`;
        }
      }).join('\n');
    }
  },

  rm: {
    description: 'Remove a file',
    usage: 'rm <file_name>',
    fn: (fileName) => {
      if (!fileName) {
        return 'Usage: rm <file_name>';
      }

      // Remove any leading/trailing whitespace and slashes
      fileName = fileName.trim().replace(/^\/+|\/+$/g, '');

      if (fileName.includes('/')) {
        return 'rm: cannot remove directories, use rmdir instead';
      }

      if (currentDirectory[fileName]) {
        if (typeof currentDirectory[fileName] === 'object') {
          return `rm: cannot remove '${fileName}': Is a directory`;
        }
        delete currentDirectory[fileName];
        return `File '${fileName}' removed successfully`;
      } else {
        return `rm: cannot remove '${fileName}': No such file`;
      }
    }
  },

  rmdir: {
    description: 'Remove an empty directory',
    usage: 'rmdir <directory_name>',
    fn: (dirName) => {
      if (!dirName) {
        return 'Usage: rmdir <directory_name>';
      }

      // Remove any leading/trailing whitespace and slashes
      dirName = dirName.trim().replace(/^\/+|\/+$/g, '');

      const parts = dirName.split('/');
      let currentDir = currentDirectory;
      //let parentDir = null;
      let finalPart = parts[parts.length - 1];

      for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        if (!currentDir[part] || typeof currentDir[part] !== 'object') {
          return `rmdir: cannot remove '${dirName}': No such file or directory`;
        }
        //parentDir = currentDir;
        currentDir = currentDir[part];
      }

      if (!currentDir[finalPart]) {
        return `rmdir: cannot remove '${dirName}': No such file or directory`;
      }

      if (typeof currentDir[finalPart] !== 'object') {
        return `rmdir: cannot remove '${dirName}': Not a directory`;
      }

      if (Object.keys(currentDir[finalPart]).length > 0) {
        return `rmdir: failed to remove '${dirName}': Directory not empty`;
      }

      delete currentDir[finalPart];
      return `Directory '${dirName}' removed successfully`;
    }
  },

  pwd: {
    description: 'Print working directory',
    usage: 'pwd',
    fn: () => currentPath
  },

  cat: {
    description: 'View file contents',
    usage: 'cat <filename>',
    fn: (filename) => {
      if (currentDirectory[filename]) {
        if (typeof currentDirectory[filename] === 'string') {
          return currentDirectory[filename];
        } else {
          return `${filename} is a directory, not a file`;
        }
      } else {
        return `File not found: ${filename}`;
      }
    }
  },
  gui: {
    description: 'Launch a simple GUI website',
    usage: 'gui',
    fn: () => {
      const url = 'https://www.bisheshank.com/simple.html';
      window.open(url, '_blank');
      return 'Launching simple GUI website...';
    }
  },
};

